<!--
 * @Description: 品质管理-类目管理-配置类目
 * @Author: 马俊
 * @Date: 2020-11-11 13:16:21
 * @LastEditors: 马俊
 * @LastEditTime:
 -->
<template>
  <div class="configCategoryList-wrapper">
    <list
      ref="list"
      :exportPermission="'export'"
      :searchUrl="searchUrl"
      :exportUrl="exportUrl"
      :exportMethod="exportMethod"
      :searchParams="searchParams"
      :isMultiSelect="false"
      :headers="headers"
    >
      <template #headerSlot>
        <v-button text="新增类目" @click="create"></v-button>
        <v-button text="返回" @click="goBack"></v-button>
      </template>

      <template #searchSlot>
        <v-input label="类目名称" placeholder="请输入类目名称" v-model="searchParams.categoryName" />
        <v-input label="类目编码" placeholder="请输入类目编码" v-model="searchParams.categoryCode" />
        <v-datepicker
          type="rangedatetimer"
          label="创建时间段"
          format="YYYY-MM-DD HH:mm"
          :startTime.sync="searchParams.startTime"
          :endTime.sync="searchParams.endTime"
        />
        <v-select label="类目状态" v-model="searchParams.status" :options="categoryStatusOps"/>
      </template>
      <template #operateSlot="scope">
        <v-button v-if="isShowEditBtn(scope.row)" text="编辑" type="text" @click="edit(scope.row)" />
      </template>

    </list>
  </div>
</template>

<script>
import {
  getcategoryConfigurationListURL
} from './api'
import {
  categoryStatusOps,
  categoryStatusMap,
  categoryLevelMap,
  categoryLevelOps
} from './map'
// import { createHTMLVNode } from 'common/vdom'
// import { mapHelper } from 'common/utils'

export default {
  name: 'qualityConfigCategoryList',
  components: {
  },
  computed: {
    userType () {
      return this.$store.state.userInfo.userType
    }
  },
  data () {
    return {
      searchUrl: getcategoryConfigurationListURL,
      exportUrl: 'x',
      exportMethod: 'get',
      searchParams: {
        categoryName: '',
        categoryCode: '',
        startTime: '',
        endTime: '',
        status: undefined
      },
      headers: [
        {
          prop: 'categoryName',
          label: '类目名称'
        },
        {
          prop: 'categoryCode',
          label: '类目编码'
        },
        {
          prop: 'parentName',
          label: '上级类目',
          formatter (row) {
            return row.parentName || '--'
          }
        },
        {
          prop: 'categoryLevel',
          label: '类目级别',
          formatter (row) {
            return categoryLevelMap[row.categoryLevel]
          }
        },
        {
          prop: 'status',
          label: '类目状态',
          formatter (row) {
            return categoryStatusMap[row.status]
          }
        },
        {
          prop: 'intime',
          label: '创建时间'
        }
      ],
      categoryStatusOps: categoryStatusOps(1),
      categoryLevelOps: categoryLevelOps(1)
    }
  },
  created () {
    this.$setBreadcrumb('配置类目')
  },
  methods: {
    goBack () {
      this.$router.go(-1)
    },

    create () {
      this.$router.push({
        name: 'qualityCategoryForm'
      })
    },

    edit (row) {
      console.log(row.id)
      this.$router.push({
        name: 'qualityCategoryForm',
        query: {
          id: row.id
        }
      })
    },

    isShowEditBtn (row) {
      if (this.userType == '106') {
        return true
      }
      if (this.userType == '102' && row.hierarchy == '1') {
        return true
      }
      if (this.userType == '100') {
        return true
      }
      return false
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
